import React, {useContext} from 'react';
import '../App.css';

import Nav from '../components/Nav'; 
import { UserContext } from '../context/userContext';


const Profile = () => {
    const [userContext, dispatch] = useContext(UserContext);

    const getProfilePic = () => {
        if(userContext.image){
            return `profilePictures/${userContext.image}.jpg`
        } else {
            return "profilePictures/cute-orangutan.jpg"
        }
        
    }

    return (
        <>
        <div className="full-profile-page">
            <Nav />
            <div className="profile-page-container">
                <div className="profile-card-container mb-3">
                    <h3 className="elo-rating">Elo Rating <span className="user-elo-rating">{userContext.user.rating}</span></h3>   
                    <header>
                        <img className="profile-avatar" src={getProfilePic()} alt="Profile Pic" width="500" height="600" />
                    </header>
                    <h3 className="user-name">
                        {userContext.user.name}
                    </h3>
                    <div className="user-info-container">
                        <ul className="user-info-list">
                            <li>{userContext.user.role}</li>
                            <li>Best Streak: {userContext.user.streak}</li>
                            <li>Challenges Done: 19,123</li>
                            <li>{userContext.user.location}</li>
                        </ul>
                    </div>
                </div>
                <div className="profile-details-container">
                    <div className="streak-tiles">
                        <span>Your best streak</span>
                    </div>
                    <div className="profile-details">
                        <div className="account-info-item">
                            <div className="card-field">About Me:</div> <div className="card-data"> I love learning languages! </div>
                        </div>
                        <hr/>
                        <div className="account-info-item">
                            <div className="card-field">First Language:</div> <div className="card-data">English</div>
                        </div>
                        <hr/>
                        <div className="account-info-item">
                            <div className="card-field">Registered:</div> <div className="card-data"> {userContext.user.registration_date}</div> 
                        </div>  
                        <hr/>
                        <div className="account-info-item">
                            <div className="card-field">Languages Studying:</div> <div className="card-data"> French, Spanish</div>
                        </div>   
                    </div>
                </div>
            </div>
        </div>

        </>
    );
};

export default Profile;

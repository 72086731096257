import React, {useEffect, useState, useContext} from 'react';
import '../App.css';

import Nav from '../components/Nav';
import GameCard from '../components/GameCard';
import LoadingSpinner from '../components/LoadingSpinner';

import { Challenge } from '../model/model.t'
import { UserContext } from '../context/userContext';
import GameStats from '../components/GameStats';
import QuestionStats from '../components/QuestionStats';
import { AnswerStatus } from '../model/model.t';
import { config } from '../config/apiConfig';
import { updateEloRatings } from '../APIs/ratingsApi';

const API_URL = config.url;

const Game = () => {
    const [userContext, dispatch] = useContext(UserContext);
    const [challenge, setChallenge] = useState<Challenge[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [questionIndex, setQuestionIndex] = useState(0);
    const [challengeRating, setChallengeRating] = useState<string | undefined>();
    const [answerStatus, setAnswerStatus] = useState<AnswerStatus>(AnswerStatus.NotSubmitted);

    let interval: any;

    useEffect(() => {
      console.log('Game useEffect hook called', challenge)
      if(!challenge || challenge.length === 0){
          fetchQuestions();
          }
  },[]);

  useEffect(() => {
    if(answerStatus===AnswerStatus.Incorrect)
      interval = setInterval(() => { 
        setAnswerStatus(AnswerStatus.NotSubmitted)
    }, 1000) 
    return () => {clearInterval(interval)}
  },[answerStatus])

  //TO DO: display potential gain and loss for each challenge
  // useEffect(() => {
  //   console.log("new question")
  //   console.log(challenge[questionIndex], userContext.user.rating)
  // }, [questionIndex, challenge, userContext.user.rating])

  const updateRatings = (userWins: boolean) => {
    calculateNewRatings(challenge[questionIndex].elo, userContext.user.rating, 30, userWins)
  }

  const calculateNewRatings = (questionRatingString: string,  userRatingString: string, kFactor: number = 30, userWins: boolean) => {
    let userRating: number = Number(userRatingString)
    let questionRating: number = Number(questionRatingString)

    const expectedScore = (self: number, opponent: number) => 1 / (1 + 10 ** ((opponent - self) / 400));
    const newRating = (rating: number, i: number) =>
    rating + kFactor * (i - expectedScore(i ? userRating : questionRating, i ? questionRating : userRating));

    const NEW_CHALL_RATING: string = Math.floor(newRating(questionRating, userWins ? 0 : 1)).toString();
    const NEW_USER_RATING: string = Math.floor(newRating(userRating, userWins ? 1 : 0)).toString();

    setChallengeRating(NEW_CHALL_RATING)
    dispatch({
      type: 'UPDATE_RATING', 
      payload: { rating : NEW_USER_RATING },
    });
  
    updateEloRatings(NEW_USER_RATING, userContext.user.email, NEW_CHALL_RATING, challenge[questionIndex].number);
  }

    const fetchQuestions = async () => {
        // setIsLoading(true);
        console.log('getting questions', isLoading);
        try{
          const response = await fetch(`${API_URL}/api/questions`, {
              headers: {'Content-Type': 'application/json'},
              credentials: 'include',
          });
          const content = await response.json();   

          setChallenge(content);
          setChallengeRating(content[0].elo)
          setQuestionIndex(0);
        } catch (err){
            // setIsLoading(false);  
            console.log("Error in app.tsk", err);
        }
      }

    const getNextChalenge = () => {
        // If the end of the array is reached, reset to zero (start), otherwise increment index
        if (questionIndex === challenge.length -1) {
            fetchQuestions();
          } else {
            setQuestionIndex(questionIndex + 1);
            setChallengeRating(challenge[questionIndex+1].elo)
          }
        };

    return (
        <>
        <Nav />
        <div className="game-page-container">
          <GameStats challenge={challenge[questionIndex]}
                      challengeRating={challengeRating} 
                      answerStatus={answerStatus}/>
          {isLoading ? <LoadingSpinner /> : challenge?.length > 0 && 
          <div className="game-input-container">
            <div className="challenge-grammer-details"><span>{challenge[questionIndex].category} - {challenge[questionIndex].mood} - {challenge[questionIndex].tense}</span> </div>
            <GameCard challenge={challenge[questionIndex]} 
                      getNextChalenge={getNextChalenge} 
                      updateRatings={updateRatings} 
                      answerStatus={answerStatus} 
                      changeAnswerStatus={(answerStatus) => setAnswerStatus(answerStatus)}
                      disabled={false}/>
          </div>}
          <QuestionStats challenge={challenge[questionIndex]}/>
        </div>
        </>
    );
};

export default Game;

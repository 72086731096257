import { config } from "../config/apiConfig";

export const updateEloRatings = async (userRating: string, email: string, elo: string, id: string) => {
    try{
      const response = await fetch(`${config.url}/api/ratings`, {
        method: 'POST',  
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify({
            userRating,
            email,
            elo,
            id
        })
      });
      const content = await response.json();   
     
    } catch (err){
        console.log("Error calling /ratings endpoint", err);
    }
  }
import { FC, useContext } from 'react';
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { UserContext } from '../context/userContext';

interface RequireAuthProps {
    allowedRoles: string
}

const RequireAuth: FC<RequireAuthProps>  = ({ allowedRoles }) => {
    const [userContext, _] = useContext(UserContext);
    const location = useLocation();
    return(
        userContext.isLogin 
        ? <Outlet />
        : <Navigate to="/login" state={{ from: location }} replace />)
    // return (
    //     auth?.roles?.find(role => allowedRoles?.includes(role))
    //         ? <Outlet />
    //         : auth?.user
    //             ? <Navigate to="/unauthorized" state={{ from: location }} replace />
    //             : <Navigate to="/login" state={{ from: location }} replace />
    // );
}

export default RequireAuth;
// import { useContext, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
// import { UserContext } from './context/userContext';

import Login from "./pages/Login";
import Home from "./pages/Home";
import Register from "./pages/Register";
import Game from "./pages/Game";
import Profile from "./pages/Profile";
import Analytics from "./pages/Analytics";
import RequireAuth from './components/RequireAuth';

function App() {

    return (
            <>
            <Routes>
                {/* Public routes */}
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />

                {/* Private Routes */}
                <Route element={<RequireAuth allowedRoles={["USER"]} />}>
                    <Route path="/game" element={<Game />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/analytics" element={<Analytics />} />
                </Route>

            </Routes>
          </>
    );
}

export default App;

import {useContext, useEffect, useState} from 'react';
import {Link} from "react-router-dom";

import Nav from '../components/Nav'
import PreviewGame from '../components/PreviewGame';
import { UserContext } from '../context/userContext';
import { config } from '../config/apiConfig';

const API_URL = config.url;

const Home = () => {
    const [state, dispatch] = useContext(UserContext);
    
    useEffect(() => {
        (
            async () => {
                try{
                    const response = await fetch(`${API_URL}/api/user`, {
                        headers: {'Content-Type': 'application/json'},
                        credentials: 'include',
                    });
                    if(response.ok){
                        const content = await response.json();   
                        dispatch({
                            type: 'LOGIN_SUCCESS',
                            payload: {
                                name : content.name,
                                email : content.email,
                                rating : content.rating, 
                            }});
                    }
                } catch (err){
                    console.log("Error in app.tsk", err)
                }
            }
        )();
    },[]);

    return (
        <>
        <Nav />
        <div>
        <div className="home-page-container">
            <div className="home-details">
                {state.isLogin && <div className="home-box username"> <span>{state.user.name}</span></div>}
                {state.isLogin && <div className="home-box score"> <span>French: {state.user.rating}</span> </div>}
            </div>

            <div className="site-name">
                <div className="hide-site-name">
                    <h1 className="site-name-text">GRAMR..</h1></div>
                </div>
            {state.isLogin &&
                <Link to="/game" className="play-link-container home-play-link"><span>PLAY</span></Link> }
            <div> <a href="more-details"></a></div>
            {!state.isLogin && <a className="nav-link" href="#about">
                <svg className="arrows arrows-home-page">
                    <path className="a1" d="M0 0 L30 32 L60 0"></path>
                </svg>
            </a>}
        </div>
        </div>
        {!state.isLogin && 
        <><section id="about" className="home-section more-details">
            <div className="more-details-title"> 
                <h1>What is an ELO rating..</h1>
            </div>
            <div className="more-details-body"> 
                <p>The application is designed as a game and thus offers short lessons that must be unlocked as the learner proceeds. It also features written, dictation, and speaking exercises.
                    The learner progresses through a language tree learning new words by completing lessons and skills. As a reward and motivational feature skill points, gems and achievements are awarded as learners practice or complete lessons.</p>
            </div>
            <a className="nav-link" href="#preview-game">
                <svg className="arrows arrows-more-details">
                    <path className="a2" d="M0 0 L30 32 L60 0"></path>
                </svg>
            </a>
        </section>
        <section id="preview-game" className="home-section preview-game">
          <PreviewGame/>
        </section>
        </>}
        </>
    );
};

export default Home;

import React, {SyntheticEvent, useState, useEffect} from 'react';
import {Navigate} from 'react-router-dom';
import '../App.css';

import Nav from '../components/Nav'; 
import { GeoResponse } from '../model/model.t';
import { config } from '../config/apiConfig';

const API_URL = config.url;

const Register = () => {
    const ROLE = 'student';

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [location, setLocation] = useState('');
    const [password, setPassword] = useState('');
    const [redirect, setRedirect] = useState(false);
    const [usernameErrorMessage, setusernameErrorMessage] = useState<string>('');
    const [emailErrorMessage, setEmailErrorMessage] = useState<string>('');
    const [passwordErrorMessage, setpasswordErrorMessage] = useState<string>('');
    const [registrationDate, setregistrationDate] = useState<string>('');

    useEffect(() => {
        getGeoInfo();
        setregistrationDate(new Date().toLocaleDateString());
    }, []);

    useEffect(() => {
        // Clear username input error message
        if(!!usernameErrorMessage){
            setusernameErrorMessage('')
        }
    }, [name]);

    useEffect(() => {
        // Clear email input error message
        if(!!emailErrorMessage){
            setEmailErrorMessage('')
        }
    }, [email]);

    useEffect(() => {
        // Clear password input error message
        if(!!passwordErrorMessage){
            setpasswordErrorMessage('')
        }
    }, [password]);

    const userNameIsNotTaken = (): boolean => {
        if(!name){
            setusernameErrorMessage('Username field is empty')
            return false;
        }
        // To do: check DB
        return true;
    }

    const emailIsValid = (): boolean => {
        const emailRegex = /[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

        if (!email) {
            setEmailErrorMessage('Email field is empty');
            return false;
        } else if (!emailRegex.test(email)) {
            setEmailErrorMessage('Email is not valid');
            return false;
        } else {
            return true;
        }
    };

    const passwordIsValid = (): boolean => {
        if(password.length < 4){
            setpasswordErrorMessage('Password must be greater than 4 characters');
            return false;
        } else{
            return true;
        }
    }

    const inputFieldsAreValid = ():boolean => {
        const isUserNameValid = userNameIsNotTaken();
        const isPasswordValid = passwordIsValid();
        const isEmailValid = emailIsValid();
    
        return isUserNameValid && isPasswordValid && isEmailValid;    
    }

    const getGeoInfo = async () => {
        let response: GeoResponse;
        try{
            const fetchedResponse = await fetch('https://ipapi.co/json/',{
                method: 'GET'});   
            response = await fetchedResponse.json();
            setLocation(response.country_name);

        } catch(error){
            console.error('Error fetching or parsing data from ipapi:', error);
        }
        return
    };

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();
        if (!inputFieldsAreValid()) {
            setRedirect(false);
        } else {
let registrationDate = new Date().toLocaleDateString();
            try{
                await fetch(`${API_URL}/api/register`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        name,
                        email,
                        password,
                        registrationDate,
                        location,
                        'role': ROLE,
                    })
                });
                setRedirect(true);
            } catch(error){
                console.error('Error POSTing data to /register:', error);
            }
            
        }
    }

    if (redirect) {
        return <Navigate to="/login" />;
    }

    return (
        <>
            <Nav />
            <form className="form-signin" onSubmit={submit}>
                <div className="registration-title"><h1>GRAMR</h1></div>
                <h1 className="h3 mb-3 fw-normal">Enter your username, email, and password</h1>
                <div className="registration-fields-container">
                    <input className="form-control" placeholder="Username"
                        onChange={e => setName(e.target.value)}
                    />
                    <div className="form-error-component">{usernameErrorMessage}</div>

                    <input type="email" className="form-control" placeholder="Email address" autoComplete="email"
                        onChange={e => setEmail(e.target.value)}
                    />
                    <div className="form-error-component">{emailErrorMessage}</div>

                    <input type="password" className="form-control password-input" placeholder="Password"
                        onChange={e => setPassword(e.target.value)}
                    />
                    <div className="form-error-component">{passwordErrorMessage}</div>

                </div>
                <button
                    className="w-100 btn btn-lg btn-primary register-button"
                    type="button"
                    onClick={submit}
                >
                    Register
                </button>
            </form>
        </>
    );
};

export default Register;

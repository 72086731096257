import React, {useContext, useReducer} from 'react';
import {Link, Navigate, useLocation} from "react-router-dom";
import { UserContext } from '../context/userContext';
import { config } from '../config/apiConfig';

const API_URL = config.url;

const Nav = () => {
    const [state, dispatch] = useContext(UserContext);
    const location = useLocation();

    const logout = async () => {
        await fetch(`${API_URL}/api/logout`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include',
        });
        console.log('calling logout')
        dispatch({
            type: "LOGOUT"
        })
        return <Navigate to="/login"/>;
    }

    let menu;

    if (state.user.name === '' || state.user.name === undefined) {
        menu = (
            <ul className="navbar-nav me-auto mb-md-0">
                {location.pathname == "/home" || "#about" && (
                <li className="nav-item active">
                        <a className="nav-link" href="#about">About</a>
                    </li>
                )}
                {location.pathname !== "/login" && (
                    <li className="nav-item active">
                        <Link to="/login" className="nav-link">Login</Link>
                    </li>
                )}
                {location.pathname !== "/register" && (
                    <li className="nav-item active">
                        <Link to="/register" className="nav-link">Register</Link>
                    </li>
                )}
            </ul>
        )
    } else {
        menu = (
            <ul className="navbar-nav me-auto mb-md-0">
                <li className="nav-item active">
                    <Link to="/game" className="nav-link play-link">Play</Link>
                </li>
                <li className="nav-item active">
                    <Link to="/analytics" className="nav-link">Analytics</Link>
                </li>
                <li className="nav-item active">
                    <Link to="/profile" className="nav-link">User Profile</Link>
                </li>
                <li className="nav-item active">
                    <Link to="/login" className="nav-link" onClick={logout}>Logout</Link>
                </li>

            </ul>
        )
    }

    return (
        <nav className="navbar navbar-expand-md navbar-dark bg-dark mb-4">
            <div className="container-fluid">
                <Link to="/" className="navbar-brand">Home</Link>

                <div className="pages-link">
                    {menu}
                </div>
            </div>
        </nav>
    );
};

export default Nav;

import React, {SyntheticEvent, useState, useEffect} from 'react';
import {Navigate} from "react-router-dom";
import { UserContext } from '../context/userContext';
import { config } from '../config/apiConfig';
import '../App.css';

import Nav from '../components/Nav';

// interface Error{
//     body?: object
//     bodyUsed: boolean
//     headers: object
//     ok: boolean
//     redirected: boolean
//     status: number
//     statusText: string
//     type: string
//     url: string
// }

const INCORRECT_EMAIL_ERROR: string = 'The email is incorrect. Please try again.';
const INCORRECT_PASSWORD_ERROR: string = 'The password is incorrect. Please try again.'; 
const API_URL = config.url;

const Login = () => {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [redirect, setRedirect] = useState<boolean>(false);
    const [loginErrorMessage, setLoginErrorMessage] = useState<string>('')

    useEffect(() => {
        setLoginErrorMessage('')
    }, [email])

    useEffect(() => {
        setLoginErrorMessage('')
    }, [password])


    const handleLoginError = (error: Response): void => {
        if(error.status == 401){
            setLoginErrorMessage(INCORRECT_EMAIL_ERROR)
        } else if (error.status == 404){
            setLoginErrorMessage(INCORRECT_PASSWORD_ERROR)
        }
    }

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();
        setLoginErrorMessage('')
        setLoading(true);

        try{
            const response = await fetch(`${API_URL}/api/login`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                credentials: 'include',
                body: JSON.stringify({
                    email,
                    password
                })
            });
            if(!response.ok) {
                handleLoginError(response);

            } else {
                setRedirect(true);

            }
            setLoading(false);
        } catch (err) {            
            console.log("err in Login: ", err)
            setLoading(false);
        }

    }

    if (redirect) {
        return <Navigate to="/"/>;
    }

    return (
        <>
        <Nav />
        <form className="form-signin" onSubmit={submit}>
            <div className="registration-title"><h1>GRAMR</h1></div>
            <h1 className="h3 mb-3 fw-normal">Log in</h1>
            <div className="registration-fields-container">

                <input type="email" className="form-control login-email-input" placeholder="Email address" required
                    onChange={e => setEmail(e.target.value)}
                />

                <input type="password" className="form-control login-password-input" placeholder="Password" required
                    onChange={e => setPassword(e.target.value)}
                />
            </div>
            <div className="form-error-component">{loginErrorMessage}</div>

            <button className="w-100 btn btn-lg btn-primary register-button"
                    type="button"
                    disabled={loading}
                    onClick={submit}>
                    Log in
            </button>
        </form>
        </>
    );
};

export default Login;

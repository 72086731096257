type Config = {
  url: string;
};
  
const prod: Config = {
  url: 'https://gramr.ie',
};
  
const dev: Config = {
    url: 'http://localhost:1323',
};
  
export const config: Config = process.env.NODE_ENV === 'development' ? dev : prod;
  
import '../App.css';

import Nav from '../components/Nav';
import AreaChart from'../components/Analytics/AreaChart'
import PieChart from '../components/Analytics/PieChart';

const Analytics = () => {
    

    return (
        <>
        <div className="analytics-page">
            <Nav />
            <div className="analytics-container">
                <div className="dashboard-title"><h3>Dashboard - Spanish</h3></div>
                <div className="stats-cards-container">
                    <div className="card stats-card total-completed"><h3>1700</h3><span>Total Challenges Completed</span></div>
                    <div className="card stats-card completed-today"><h3>10</h3><span>Challenges Completed Today</span></div>
                    <div className="card stats-card success-rate"><h3>87%</h3><span>Success Rate</span></div>
                    <div className="card stats-card streak"><h3>3 Days</h3><span>Current Streak</span></div>
                </div>
                <div className="graph-charts-container">
                    <div className="card elo-area-chart">
                        <AreaChart />
                    </div>
                    <div className="card correct-pie-chart">
                        <PieChart chartTitle="Correct Answers" />
                    </div>
                    <div className="card wrong-pie-chart">
                        <PieChart chartTitle="Wrong Answers"  />
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default Analytics;

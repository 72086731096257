import { createContext, useEffect, useReducer } from "react";

export const UserContext = createContext();

var initialState = {
  isLogin: false,
  user: {},
};

const reducer = (state, action) => {
  const { type, payload } = action;
  
  console.log('reducer called: ', type, payload)
  switch (type) {
    // add case "USER_SUCCESS" here ..
    case 'LOGIN_SUCCESS':
      // Set localstorage item with key "token" here ...
      console.log('storing value', JSON.stringify(payload))
      localStorage.setItem('user',  JSON.stringify(payload)); // Store user details in local storage

      return {
        isLogin: true,
        user: payload,
      };
    // add case "AUTH_ERROR" here ..
    case 'LOGOUT':
      console.log('removing value', JSON.stringify(payload))
      localStorage.removeItem('user'); // Remove user details from local storage

      return {
        isLogin: false,
        user: "",
      };

    case 'UPDATE_RATING':
      state.user.rating = action.payload.rating;
      return state;
  
    default:
      throw new Error();
  }
};

export const UserContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  
    useEffect(() => {
    console.log('usercontext hook called')
    const savedUser = localStorage.getItem('user');
    console.log('savedUser', savedUser)
    if(savedUser){
      // initialState = {
      //   isLogin: true,
      //   user: savedUser
      // }
      dispatch({
        type: 'LOGIN_SUCCESS',
        payload: JSON.parse(savedUser),
    });
    }
   },[]);

  return (
    <UserContext.Provider value={[state, dispatch]}>
      {children}
    </UserContext.Provider>
  );
};

import { FC, useContext } from 'react';
import { AnswerStatus, Challenge } from '../model/model.t'
import { UserContext } from '../context/userContext';
import LoadingSpinner from './LoadingSpinner';

interface GameStatsProps {
  challenge: Challenge;
  challengeRating: string | undefined;
  answerStatus: AnswerStatus
}

const GameStats: FC<GameStatsProps> = ({ challenge, challengeRating, answerStatus }) => {
  const [userContext, _] = useContext(UserContext);

  const getListItemStyle = (): string => {
    switch (answerStatus){
      case AnswerStatus.Correct:
        return "ratings-correct-answer"
      case AnswerStatus.Incorrect:
          return "ratings-incorrect-answer"
    }
    return "ratings"
  }

  if(challenge !== undefined ){
    return (
      <div className="card game-stats-card white mb-3 " >
      <div className="card-body">
        <h5 className="card-title ">Game Statistics</h5>
        <p className="card-text">Enter the correct conjugate of the verb in bold to complete this challenge.</p>
      </div>
      <ul className="list-group list-group-flush bg-light ">
     <li className="list-group-item">{userContext.isLogin ? <> <span className="game-stat-label">Player: </span> {userContext.user.name}</>
      : 'You are not logged in'}</li>
        <li className="list-group-item"><span className="game-stat-label">Player ELO:</span> <span className={getListItemStyle()}>{userContext.user.rating}</span> </li>
        <li className="list-group-item"><span className="game-stat-label">Question ELO:</span> {challengeRating}</li>
        <li className="list-group-item"><span className="game-stat-label">Gain:</span> 10</li>
        <li className="list-group-item"><span className="game-stat-label">Loss:</span> -10</li>
      </ul>
    </div>
  );
  }
  else {
    return <LoadingSpinner />
  }

}

export default GameStats;

import {useEffect, useState, useContext} from 'react';
import '../App.css';

import GameCard from '../components/GameCard';
import LoadingSpinner from '../components/LoadingSpinner';

import { Challenge } from '../model/model.t'
import { UserContext } from '../context/userContext';
import { AnswerStatus } from '../model/model.t';
import { Link } from 'react-router-dom';
import { config } from '../config/apiConfig';

const API_URL = config.url;
const NUM_FREE_CHALLENGES = 4;

const PreviewGame = () => {
    const [challenge, setChallenge] = useState<Challenge[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [questionIndex, setQuestionIndex] = useState(0);
    const [challengeRating, setChallengeRating] = useState<string | undefined>();
    const [answerStatus, setAnswerStatus] = useState<AnswerStatus>(AnswerStatus.NotSubmitted);
    const [previewExpired, setPreviewExpired] = useState<boolean>(false);


    let interval: any;

    useEffect(() => {
      if(!challenge || challenge.length === 0){
          fetchQuestions();
          }
  },[]);

  useEffect(() => {
    if(answerStatus===AnswerStatus.Incorrect)
      interval = setInterval(() => { 
        setAnswerStatus(AnswerStatus.NotSubmitted)
    }, 1000) 
    return () => {clearInterval(interval)}
  },[answerStatus])


    const fetchQuestions = async () => {
        // setIsLoading(true);
        try{
          const response = await fetch(`${API_URL}/api/questions`, {
              headers: {'Content-Type': 'application/json'},
              credentials: 'include',
          });
          const content = await response.json();   
          setChallenge(content);
          setChallengeRating(content[0].elo)
          setQuestionIndex(0);
        } catch (err){
            // setIsLoading(false);  
            console.log("Error in app.tsk", err);
        } finally{
            // setIsLoading(false);  
        }
      }

    const getNextChalenge = () => {
        // 
        if (questionIndex === NUM_FREE_CHALLENGES) {
            setPreviewExpired(true);
          } else {
            setQuestionIndex(questionIndex + 1);
            setChallengeRating(challenge[questionIndex+1].elo)
          }
        };

    return (
        <>
        <div className="game-page-container">
          {isLoading ? <LoadingSpinner /> : challenge?.length > 0 && 
          <div className="game-input-container">
            <div className="challenge-grammer-details"><h3>Conjugate the verb in bold correctly..</h3> </div>
            <GameCard challenge={challenge[questionIndex]} 
                      getNextChalenge={getNextChalenge} 
                      updateRatings={()=>(console.log("update ratings printed"))} 
                      answerStatus={answerStatus} 
                      changeAnswerStatus={(answerStatus) => setAnswerStatus(answerStatus)}
                      disabled={previewExpired}/>
            {previewExpired && 
            <>
            <div className="hide-buttons">
                <Link to="/login" className="w-100 btn btn-lg btn-primary register-button"><span>Log In</span></Link> 
                <Link to="/register" className="w-100 btn btn-lg btn-primary register-button"><span>Register</span></Link> 
            </div>
            </>}
          </div>}
        </div>
        </>
    );
};

export default PreviewGame;

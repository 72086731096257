import React from 'react';
import ReactApexChart from 'react-apexcharts';

interface PieChartProps {
  chartTitle: string;
}

const PieChart: React.FC<PieChartProps> = ({ chartTitle })  => {

    const state = {
          
        series: [25, 15, 44, 55, 41, 17],
        options: {
          chart: {
            width: '100%',
            type: 'pie',
          },
          // labels: [],
          theme: {
            monochrome: {
              enabled: true
            }
          },
          plotOptions: {
            pie: {
              dataLabels: {
                offset: -5
              }
            }
          },
          dataLabels: {
            formatter(val, opts) {
              const name = opts.w.globals.labels[opts.seriesIndex]
              return [name, val.toFixed(1) + '%']
            }
          },
          legend: {
            show: false
          }
        },
      };
    
    return (
      <>
      <div>
          <div className="elo-chart-title"> {chartTitle}</div>
          <ReactApexChart options={state.options} series={state.series} type="pie" />
          <div id="html-dist"></div> 
      </div>
      </>
    );
    }

    export default PieChart
 export interface Challenge {
    number:     string;
	question:   string;
	answer:     string;
	category:   string;
	mood:       string;
	tense:      string;
	person:     string;
	quantity:   string;
	ending:     string;
	regularity: string;
	pronominal: string;
	elo:        string;
	k:         string;
  }

  export interface User {
    name:     string;
  }

  export enum AnswerStatus {
	Correct,
	Incorrect,
	NotSubmitted
  }

  export interface GeoResponse extends Response{
	country_name: string
  }
import { FC } from 'react';

interface CharacterKeyboardProps {
    answerInput: string;
    setAnswerInput: (answerInput: string) => void;  // Example of a callback function prop
}

const CharacterKeyboard: FC<CharacterKeyboardProps> = ({ answerInput, setAnswerInput }) => {

  const accentedChars= [
    "é",
    "à",
    "è",
    "ù",
    "ç",
    "â",
    "ê",
    "î",
    "ô",
    "û",
    "ë",
    "ï",
    "ü"];

  return(
    <>
    <div className="keyboard-container">
        {accentedChars.map((char) => (
        <button type="button" className="btn btn-outline-dark" onClick={() => setAnswerInput(char)}>{char}</button>
        ))}
    </div>
    </> );
}

export default CharacterKeyboard;

import { FC, useContext, useEffect, useState } from 'react';
import { Challenge } from '../model/model.t'
import { UserContext } from '../context/userContext';

const QuestionStats: FC<{ challenge: Challenge }> = ({ challenge }) => {
  const [userContext, _] = useContext(UserContext);
  const [hintShown, setHintShown] = useState<boolean>(false);

  useEffect(() => {
    setHintShown(false);
  },[challenge])

  if(challenge !== undefined ){
    return (
      <div className="card question-stats-card bg-light mb-3 " >
      <div className="card-body">
        <h5 className="card-title ">Challenge</h5>
        <p className="card-text">Linguistical information about this challenge</p>
      </div>
      {!hintShown && <button className="btn btn-outline-dark" onClick={() => setHintShown(true)}>Hint</button>}
      {hintShown &&
      <ul className="list-group list-group-flush bg-light ">
        <li className="list-group-item"><span className="game-stat-label">Person:</span> {challenge.person}</li>
        <li className="list-group-item"><span className="game-stat-label">Ending:</span> {challenge.ending}</li>
        <li className="list-group-item"><span className="game-stat-label">Quantity:</span> {challenge.quantity}</li>
        <li className="list-group-item"><span className="game-stat-label">Regularity:</span> {challenge.regularity}</li>
        <li className="list-group-item"><span className="game-stat-label">Pronominal:</span> {challenge.pronominal}</li>
        <li className="list-group-item"><span className="game-stat-label">Person:</span> {challenge.person}</li>
        <li className="list-group-item"><span className="game-stat-label">Translation:</span> {challenge.k}</li>
      </ul>}
    </div>
  );
    }
    else{
      return <h1> </h1>
    }
}

export default QuestionStats;
import { FC, useState, SyntheticEvent } from 'react';
import { AnswerStatus, Challenge } from '../model/model.t';
import correctAnswerSound from '../assets/correct_answer.wav';
import skipSound from '../assets/skip_sound.wav';
import CharacterKeyboard from '../components/CharacterKeyboard';

import parse from 'html-react-parser';

interface GameCardProps {
  challenge: Challenge;
  getNextChalenge: () => void;  // Example of a callback function prop
  updateRatings: (userWins:boolean) => void;
  changeAnswerStatus: (answerStatus: AnswerStatus) => void;
  answerStatus: AnswerStatus;
  disabled: boolean;
}

const GameCard: FC<GameCardProps> = ({ challenge, getNextChalenge, updateRatings, changeAnswerStatus, answerStatus, disabled }) => {
  const [isInputDisabled, setIsInputDisabled] = useState<boolean>(false);
  const [answerInput, setAnswerInput] = useState<string>(''); 
  const handleAnswerInput = async (e: any) => {
    setAnswerInput(e.target.value) 
    console.log('handlin answer input', e)
  }

  const submitAnswer = async (e: SyntheticEvent) => {
    e.preventDefault();
    if(correctAnswer(answerInput, challenge.answer)){    
      updateRatings(true);  
      setIsInputDisabled(true)
      await correctAnswerResponse()
      setAnswerInput('')  
      resetInput() 
      getNextChalenge()
    }else{
      skipChallenge();
    }
  }

  const resetInput = () => {
    changeAnswerStatus(AnswerStatus.NotSubmitted)
  }

  const audioPlay = async (url: string) => {
    // Using web audio API to avoid sound lag on safari
    const context = new AudioContext();
    const source = context.createBufferSource();
    const audioBuffer = await fetch(url)
      .then(res => res.arrayBuffer())
      .then(ArrayBuffer => context.decodeAudioData(ArrayBuffer));
  
    source.buffer = audioBuffer;
    source.connect(context.destination);
    source.start();
};

  const correctAnswerResponse = async () => {
    audioPlay(correctAnswerSound);
    changeAnswerStatus(AnswerStatus.Correct);
    await sleep(500);
    setIsInputDisabled(false);
  }

  const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

  const correctAnswer = (userInput: string, answer: string): boolean => {
  return userInput.toUpperCase() === answer.toUpperCase()
  }

  const skipChallenge = async () => {
    audioPlay(skipSound)
    changeAnswerStatus(AnswerStatus.Incorrect)
    updateRatings(false);  
    getNextChalenge()
    setAnswerInput('')  
  }

 const getLabelStyles  = (): string => {
  switch(answerStatus){
    case AnswerStatus.Correct:
      return "game-card-form-label correct-answer"
    case AnswerStatus.Incorrect:
      // return  "game-card-form-label incorrect-answer"
    case AnswerStatus.NotSubmitted:
    default:
      return "game-card-form-label"
  }
 } 

 const getInputStyles  = (): string => {
  switch(answerStatus){
    case AnswerStatus.Correct:
      return "form-control correct-answer"
    case AnswerStatus.Incorrect:
      // return "form-control incorrect-answer"
    case AnswerStatus.NotSubmitted:
    default:
      return "form-control"
  }
 } 
 const keyboardPressed = (e: string) => {
  setAnswerInput(answerInput + e)  
 };

  return (
    <form className="form-game-card " onSubmit={submitAnswer}>
      <div className="mb-3">
       <label htmlFor="exampleInputChallenge" className={getLabelStyles()}>{parse(challenge.question)}</label>
         <input type="answer" 
              value={answerInput} 
              className={getInputStyles()}
              id="exampleInputChallenge" 
              readOnly={isInputDisabled}
              onChange={handleAnswerInput}
              disabled={disabled}
               />
      <div className="button-container">
        <CharacterKeyboard answerInput={answerInput} setAnswerInput={keyboardPressed}/>
        <div className="skip-submit-container">
          <button type="button" className="btn btn-outline-dark" onClick={skipChallenge}>Skip</button>
          <button type="button" className="btn btn-outline-dark" onClick={submitAnswer}>Submit</button>
        </div>
     </div>
     </div>
    </form>
  );
}

export default GameCard;
